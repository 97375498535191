import React from 'react';

import { AlertDialog, Button } from 'native-base';

interface CustomAlertDialogProps {
    title: string;
    description: string;
    cancelText?: string;
    confirmText?: string;
    isOpen: boolean;
    cancelDisabled?: boolean;
    onCancelClicked: () => void;
    onConfirmClicked: () => void;
}

export default function CustomAlertDialog(props: CustomAlertDialogProps) {

    const [isOpen, setIsOpen] = React.useState(props.isOpen);
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef(null);

    React.useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    return <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
        <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>{props.title}</AlertDialog.Header>
            <AlertDialog.Body>
                {props.description}
            </AlertDialog.Body>
            <AlertDialog.Footer>
                <Button.Group space={2}>
                    <Button
                        variant="unstyled"
                        colorScheme="coolGray"
                        disabled={props.cancelDisabled}
                        onPress={() => {
                            onClose();
                            props.onCancelClicked();
                        }} ref={cancelRef}>
                        {props.cancelText ? props.cancelText : "Cancel"}
                    </Button>
                    <Button onPress={() => {
                        onClose();
                        props.onConfirmClicked();
                    }}>
                        {props.confirmText ? props.confirmText : "OK"}
                    </Button>
                </Button.Group>
            </AlertDialog.Footer>
        </AlertDialog.Content>
    </AlertDialog>
};