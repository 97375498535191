import React, { useEffect } from 'react';
import csv from 'csvtojson';
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import { Box, Text, Input, View, Button } from 'native-base';
import { textStyle } from 'src/styles/textStyle';
import { tableStyle } from 'src/styles/tableStyle';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
import * as JobActions from '../../redux/actions/jobAction';

export interface IDisplayCsvTableComponentProps {
  jobId?: string;
  result_field?: string;
  title: string;
  // indexes based on the table data or csv file
  columnsToDisplay: number[];
  tableHead?: any;
  tableData?: any;
  csvFileUrl?: string | undefined;
  isEditable?: boolean;
  // indexes based on the displayed columns
  editableColumns?: number[];
  total_kernels?: number;
}

export default function DisplayCsvTableComponentV2(props: IDisplayCsvTableComponentProps) {

  const dispatch = useDispatch();
  const colorMode = useSelector((store: IStore) => store.app.colorMode);

  const [state, setState] = React.useState({
    title: "",
    columns: [],
    tableHead: [],
    showHead: true,
    tableData: [[], []]
  });

  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(() => {
    if (props.csvFileUrl != null && props.csvFileUrl != undefined && props.csvFileUrl != "") {
      setTableData(props.title, props.columnsToDisplay || [], props.csvFileUrl);
    } else {
      setTableDataUsingProps()
    }
  }, []);

  useEffect(() => {
    setTableDataUsingProps()
  }, [props.tableData]);

  const setTableData = (title: string, cols: any, csvFileUrl: string) => {
    fetch(csvFileUrl)
      .then(async (response) => {
        const resp = await response.text();
        csv({
          noheader: true,
          output: "csv"
        }).fromString(resp)
          .then((csvRow) => {
            setState({
              ...state,
              title: title,
              columns: cols || Array.from(Array(csvRow[0].length).keys()),
              tableHead: csvRow[0],
              tableData: csvRow.slice(1)
            });
          })
      })
      .catch((error) => {
        console.error("some error occurred", error);
      });
  }

  const setTableDataUsingProps = () => {
    setState({
      ...state,
      title: props.title,
      columns: props.columnsToDisplay,
      tableHead: props.tableHead,
      tableData: props.tableData,
    });
  }

  const beautify = (data: any) => {
    if (!isNaN(parseFloat(data)) && !isNaN(data - 0)) {
      let floatData = parseFloat(data);
      if (Math.ceil(floatData) == Math.floor(floatData)) {
        return data;
      } else {
        return floatData.toFixed(2);
      }
    } else {
      return data;
    }
  }

  const updateTableData = (rowIndex: number, colIndex: number, text: string) => {
    let tableData = state.tableData;
    tableData[rowIndex][state.columns[colIndex]] = text;

    // custom logic to update the table data based on result field
    if (props.result_field === "rejection_analysis_csv") {
      if (colIndex === 1) {
        tableData[rowIndex][state.columns[colIndex + 1]] = (parseFloat(text) / props.total_kernels! * 100).toFixed(2);
      }
    }

    setState({
      ...state,
      tableData: tableData
    });
  }

  const inputElement = (data: string, rowIndex: number, colIndex: number) => (

    <Input selectTextOnFocus
      scrollEnabled={false}
      key={`${rowIndex},${colIndex}`}
      value={
        colIndex === 0 ? data
          : (isEditing ? data : beautify(data))
      }
      style={
        [{
          color: colorMode == "dark" ? "white" : "black",
        }]
      }
      keyboardType={"decimal-pad"}
      onChangeText={(text) => {
        updateTableData(rowIndex, colIndex, text);
      }} />
  );

  const updateResults = async () => {
    if (props.jobId === undefined
      || props.jobId === ""
      || props.result_field === undefined
      || props.result_field === "") {
      return;
    }

    const tableDataJson: any[] = [];

    for (let i = 0; i < state.tableData.length; i++) {
      const rowData: any = {};
      for (let j = 0; j < state.tableData[i].length; j++) {
        rowData[state.tableHead[j]] = state.tableData[i][j];
      }
      tableDataJson.push(rowData);
    }

    dispatch(JobActions.updateJobResult.request({
      "jobId": props.jobId,
      "data": tableDataJson,
      "result_field": props.result_field
    }));
  }

  const textElement = (data: any) => (
    <Text style={[tableStyle.cellText, {
      color: colorMode === 'dark' ? 'white' : 'black'
    }]}>{data}</Text>
  );

  const headElement = (data: any) => (
    <Text style={tableStyle.cellText}>{data}</Text>
  );

  return (
    <Box>
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Text style={textStyle.tableTitleText}>{props.title}</Text>
        {props.isEditable
          && <Button variant={"link"} onPress={async () => {
            if (isEditing) {
              await updateResults();
            }

            setIsEditing(!isEditing);
          }}>{isEditing ? "Save changes" : "Edit"}</Button>}
      </View>
      <Table borderStyle={tableStyle.border}>
        <TableWrapper style={tableStyle.head}>
          {
            state.tableHead?.filter((cellData, index) => {
              return state.columns.includes(index);
            }).map((cellData, index) => (
              <Cell key={index} data={headElement(cellData)} />
            ))
          }
        </TableWrapper>
        {
          (state.tableData || []).map((rowData, index) => {
            return (
              <TableWrapper key={index} style={tableStyle.row}>
                {
                  rowData?.filter((rowData, index) => {
                    return state.columns.includes(index);
                  }).map((cellData, cellIndex) => (
                    <Cell
                      key={cellIndex}
                      data={
                        (!props.editableColumns?.includes(cellIndex) || !isEditing) ? textElement(beautify(cellData)) :
                          inputElement(cellData, index, cellIndex)
                      } />
                  ))
                }
              </TableWrapper>
            )
          })
        }
      </Table>
    </Box>
  );
};