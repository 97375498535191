import React, { useEffect } from 'react';
import { View, Image, FlatList, TouchableOpacity, SafeAreaView } from 'react-native';
import csv from 'csvtojson';
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import { Box, Button, HStack, IconButton, Text, Checkbox } from 'native-base';
import { S3_BUCKET_URL } from '../../constants'
import ParticleImage from '../ParticleImage';
import { containerStyle } from 'src/styles/containerStyle';
import { imageStyle } from 'src/styles/imageStyle';
import { tableStyle } from 'src/styles/tableStyle';
import { Modal } from 'native-base';
import MaterialIconByName from '../MaterialIconByName';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';

export default function DisplayIndiGrainResultsTable(props) {

  const colorMode = useSelector((store: IStore) => store.app.colorMode);

  const [filterModalVisible, setFilterModalVisible] = React.useState(false);

  const showFilterModal = () => setFilterModalVisible(true);
  const hideFilterModal = () => setFilterModalVisible(false);

  const [imageModalVisible, setImageModalVisible] = React.useState(false);

  const [resultCsvData, setResultCsvData] = React.useState(undefined);

  const showImageModal = () => setImageModalVisible(true);
  const hideImageModal = () => setImageModalVisible(false);

  const [sortByColumn, setSortByColumn] = React.useState("Serial");
  const [sortOrder, setSortOrder] = React.useState("asc");

  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

  const [filters, setFilters] = React.useState([]);
  const [selectedColumns, setSelectedColumns] = React.useState([0, 1, 2, 3]);
  const [jobId, setJobId] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(1);
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = React.useState(10);

  const [tableHead, setTableHead] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [currentPageData, setCurrentPageData] = React.useState([]);

  useEffect(() => {
    setJobId(props.jobId);
    setInitialTableData(props.csvFileUrl);
    setNumberOfItemsPerPage(props.numItemsPerPage);
  }, []);

  useEffect(() => {
    updateCurrentPageData();
  }, [page]);

  const sortItems = (column: string) => {
    if (sortByColumn === column) {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
      setSortOrder(newSortOrder)
      if (resultCsvData !== undefined) {
        updateTableData(resultCsvData, column, newSortOrder)
      }

    } else {
      setSortByColumn(column)
      setSortOrder("asc")
      if (resultCsvData !== undefined) {
        updateTableData(resultCsvData, column, 'asc')
      }
    }
  }

  const updateTableData = (data, sortBy: string, sortingOrder: string) => {
    var head = data[0]
    var data = data.slice(1)

    if (!head.includes('Serial')) {
      head.splice(0, 0, 'Serial')

      var idx = 0
      data.map((item) => {
        item.splice(0, 0, idx)
        idx += 1
        return item
      })

      setTableHead(head)
    }

    const sortedData = getSortedData(head, data, sortBy, sortingOrder)

    let rowFilters = [];
    head.forEach((item, index) => {
      rowFilters.push({
        "label": item,
        "value": index,
        "checked": selectedColumns.includes(index) ? true : false
      })
    })

    setFilters(rowFilters)

    setTableData(sortedData)
  }

  const getSortedData = (tableHead: any, tableData: any, sortBy: string, sortingOrder: string) => {
    const columnIndex = tableHead.indexOf(sortBy)
    tableData.sort((a: any, b: any) => {
      if (sortingOrder === 'asc') {
        if (a[columnIndex] === 'True' || a[columnIndex] === 'False') {
          return a[columnIndex].localeCompare(b[columnIndex])
        }
        return a[columnIndex] - b[columnIndex]
      } else {
        if (a[columnIndex] === 'True' || a[columnIndex] === 'False') {
          return b[columnIndex].localeCompare(a[columnIndex])
        }
        return b[columnIndex] - a[columnIndex]
      }

    })
    return tableData
  }

  useEffect(() => {
    tableData.slice(0, 10).map((rowData) => {
      return rowData
    })
    updateCurrentPageData();
  }, [tableData]);

  const updateCurrentPageData = () => {
    const startIndex = page * numberOfItemsPerPage;
    let endIndex = startIndex + numberOfItemsPerPage;
    if (endIndex > tableData.length) {
      endIndex = tableData.length - 1;
    }
    if (tableData.length > 1) {
      setCurrentPageData(tableData.slice(startIndex, endIndex))
    }
  }

  const setInitialTableData = (csvFileUrl: string) => {
    fetch(csvFileUrl)
      .then(async (response) => {
        const resp = await response.text();
        csv({
          noheader: true,
          output: "csv"
        }).fromString(resp)
          .then((csvRow) => {
            setResultCsvData(csvRow)
            let pages = Math.floor(csvRow.length / numberOfItemsPerPage);
            if (csvRow.length > numberOfItemsPerPage * pages) {
              pages = pages + 1;
            }

            var head = csvRow[0]
            var newCsvRow = csvRow
            if (head.includes('Volumn')) {
              const index = head.indexOf('Volumn');
              if (index > -1) {
                newCsvRow = csvRow.map((item) => {
                  item.splice(index, 1)
                  return item
                })
              }
            }

            setTotalPages(pages)
            updateTableData(newCsvRow, sortByColumn, sortOrder)
          })
      })
      .catch((error) => {
        console.error("some error occurred", error);
      });
  }

  const imageElement = (data: any) => (
    <TouchableOpacity onPress={() => {
      setSelectedImageUrl(`${S3_BUCKET_URL()}/output/${jobId}/simgPy/${data}`)
      showImageModal();
    }} >
      <Image style={props?.productType === "DOUBLE_SIDED_GRAIN_ANALYSIS" ? imageStyle.dsIndiGrainImage : imageStyle.indiGrainImage} source={{ uri: `${S3_BUCKET_URL()}/output/${jobId}/simgPy/${data}` }} />
    </TouchableOpacity>

  );

  const headElement = (data: any) => (
    <TouchableOpacity onPress={() => {
      sortItems(data)
    }} >
      <Text style={[tableStyle.cellText, {
        color: 'black'
      }]}>{data}</Text>
    </TouchableOpacity>
  );

  const textElement = (data: any) => (
    <Text style={[tableStyle.cellText, {
      color: colorMode === 'dark' ? 'white' : 'black'
    }]}>{data}</Text>
  );

  const onItemCheckChange = (item: any) => {
    let newFilters = filters
    newFilters[item.value]['checked'] = !newFilters[item.value]['checked']
    setFilters(newFilters)
    let selectedCols = []
    newFilters.forEach((item) => {
      if (item.checked) {
        selectedCols.push(item.value);
      }
    })

    setSelectedColumns(selectedCols)
  }

  const renderFilterItem = ({ item }) => {

    return (
      <View style={{
        padding: 8,
      }}>
        <Checkbox
          isDisabled={item.value === 0 || item.value === 1}
          value={item.label}
          isChecked={item.checked}
          onChange={() => {
            onItemCheckChange(item)
          }}
        >
          {item.label}
        </Checkbox>
      </View>
    );
  };

  const initialRefFilterModal = React.useRef(null);
  const finalRefFilterModal = React.useRef(null);

  const initialRefImageModal = React.useRef(null);
  const finalRefImageModal = React.useRef(null);

  return (
    <View style={containerStyle.mainContainer}>
      <Modal
        size={"lg"}
        isOpen={filterModalVisible}
        onClose={hideFilterModal}
        initialFocusRef={initialRefFilterModal}
        finalFocusRef={finalRefFilterModal}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Select columns to display</Modal.Header>
          <Modal.Body>
            <SafeAreaView style={{ flex: 1 }}>
              <FlatList
                data={filters}
                renderItem={renderFilterItem}
                keyExtractor={item => item.value}
                extraData={filters}
              />
            </SafeAreaView>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" colorScheme="blueGray" onPress={hideFilterModal}>
                Cancel
              </Button>
              <Button
                onPress={hideFilterModal}>Save filters</Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal
        size={"lg"}
        isOpen={imageModalVisible}
        onClose={hideImageModal}
        initialFocusRef={initialRefImageModal}
        finalFocusRef={finalRefImageModal}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Image</Modal.Header>
          <Modal.Body>
            <View style={{ flexDirection: 'column', flexWrap: 'wrap' }}>
              {
                selectedImageUrl !== "" ? (<>
                  <TouchableOpacity style={{ height: "100%", width: "100%" }}>
                    <View style={{ height: "100%", width: "100%", justifyContent: 'space-around' }}>
                      <ParticleImage
                        labeled_image={selectedImageUrl}
                        width={props?.productType === "DOUBLE_SIDED_GRAIN_ANALYSIS" ? 800 : 600}
                        height={props?.productType === "DOUBLE_SIDED_GRAIN_ANALYSIS" ? 400 : 600}
                      />
                    </View>
                  </TouchableOpacity>
                </>) : (<>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text>Nothing to display!</Text>
                  </View>
                </>)
              }
            </View>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Button style={{
        marginBottom: 8,
      }} onPress={showFilterModal}>
        Choose Columns
      </Button>
      <Table borderStyle={tableStyle.border}>
        <TableWrapper style={tableStyle.head}>
          {
            tableHead.filter((cellData, index) => {
              return selectedColumns.includes(index);
            }).map((cellData, index) => (
              <Cell key={index} data={headElement(cellData)} />
            ))
          }
        </TableWrapper>
        {
          currentPageData.map((rowData, index) => (
            <TableWrapper key={index} style={tableStyle.row}>
              {
                rowData.filter((rowData, index) => {
                  return selectedColumns.includes(index);
                }).map((cellData, cellIndex) => (
                  <Cell key={cellIndex} data={cellIndex === 1 ? imageElement(cellData) : textElement(cellData)} textStyle={tableStyle.cellText} />
                ))
              }
            </TableWrapper>
          ))
        }
      </Table>
      <Box>
        <HStack space={2}
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Text>
            Page {page + 1} of {totalPages}
          </Text>
          <IconButton
            onPress={() => {
              if (page > 0) {
                setPage(page - 1)
              }
            }}
            borderRadius={"full"}
            icon={<MaterialIconByName name={"chevron-left"} />} />
          <IconButton
            borderRadius={"full"}
            onPress={() => {
              if (page < totalPages - 1) {
                setPage(page + 1)
              }
            }}
            icon={<MaterialIconByName name={"chevron-right"} />} />
        </HStack>
      </Box>
    </View>
  );
};