import * as React from 'react';
import { IStore } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { Button, Box, HStack } from "native-base";
import { useEffect } from 'react';
import MaterialIconByName from '../MaterialIconByName';
import { downloadReportPdf, downloadScannedImage } from 'src/services/download';

export default function DownloadResultButtons() {

    const [showSCUserView, setShowSCUserView] = React.useState(false);

    const userGroups = useSelector((store: IStore) => store.user.userProfile.response?.groups);

    useEffect(() => {
        if (userGroups && userGroups.includes("scAppUser")) {
            setShowSCUserView(true)
        }
    }, [userGroups]);

    const job = useSelector((store: IStore) => store.job.jobStatus.job);
    
    const [downloadingImage, setDownloadingImage] = React.useState(false);
    const [fetchingReport, setFetchingReport] = React.useState(false);

    const DOWNLOAD_TIMEOUT = 3 * 1000;

    const downloadScannedImagesForJob = async () => {
        setDownloadingImage(true)
        setTimeout(() => {
            setDownloadingImage(false)
        }, DOWNLOAD_TIMEOUT)

        await downloadScannedImage(job)
    }

    const downloadPdfReportForJob = async () => {
        setFetchingReport(true)
        await downloadReportPdf(job)
        setFetchingReport(false)
    }

    return (
        <Box>
            {!showSCUserView && <HStack
                style={{
                    justifyContent: 'center',
                    marginBottom: 10,
                    marginTop: 10
                }}
                space={2}>
                {
                    job?.input_object_key !== undefined &&
                    job?.input_object_key !== "" &&
                    <Button
                        startIcon={<MaterialIconByName name="download" />}
                        isLoading={downloadingImage}
                        isLoadingText="Downloading"
                        onPress={downloadScannedImagesForJob}>
                        Scanned image(s)
                    </Button>
                }
                <Button isLoading={fetchingReport}
                    startIcon={<MaterialIconByName name="download" />}
                    isLoadingText="Generating report"
                    onPress={downloadPdfReportForJob}>
                    Report
                </Button>
            </HStack>}
        </Box>
    );

}