import React, { useEffect, useState } from 'react';
import { IStore } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as BluetoothActions from '../../redux/actions/bluetoothAction';
import { View } from 'react-native';
import { Box, Button, FormControl, IconButton, Input, VStack } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { inputStyle } from 'src/styles/inputStyle';
import CustomAlertDialog from '../CustomAlertDialog';
import CustomAlertBanner from '../CustomAlertBanner';
import MaterialIconByName from '../MaterialIconByName';

interface ConfigureWifiFormState {
  wifiSSID: string,
  wifiPassword: string
}

export default function ConfigureRpiWifi() {

  const [bannerVisible, setBannerVisible] = React.useState(false);
  const [securePasswordEntry, setSecurePasswordEntry] = React.useState(true);
  const [visible, setVisible] = React.useState(false);

  const [formData, setData] = React.useState<ConfigureWifiFormState>({
    wifiSSID: '',
    wifiPassword: ''
  });

  const [errors, setErrors] = React.useState({});

  const validate = () => {
    if (formData.wifiSSID === undefined
      || formData.wifiSSID.length === 0) {
      setErrors({
        username: 'WiFi SSID is required'
      });
      return false;
    } else if (formData.wifiPassword === undefined
      || formData.wifiPassword.length === 0) {
      console.log('password is required')
      setErrors({
        password: 'Password is required'
      });
      return false;
    }

    return true;
  };

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);

  const [buttonText, setButtonText] = useState("Configure");
  const [buttonIcon, setButtonIcon] = useState("wifi");

  const wifiConfigured = useSelector((store: IStore) => store.bluetooth.configureWifi);

  const wifiStatus = useSelector((store: IStore) => store.bluetooth.rpiStatus.status);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BluetoothActions.getRpiStatus.request());
  }, []);

  useEffect(() => {
    if (wifiStatus?.wifiStatus === "CONNECTED") {
      setBannerVisible(true);
    }
  }, [wifiStatus]);

  useEffect(() => {
    updateStatus();
  }, [wifiConfigured]);

  const updateStatus = () => {
    console.log("ConfigureRpiWifi updateStatus", wifiConfigured);
    if (wifiConfigured.configured) {
      setButtonText("Configured");
      setButtonIcon("check");
    } else if (wifiConfigured.isFetching) {
      setButtonText("Configuring...");
      setButtonIcon("sync");
    } else {
      setButtonText("Configure");
      setButtonIcon("wifi");
    }
  }

  const onSubmit = () => {
    const isValid = validate();
    if (isValid) {
      // submit the data
      showDialog()
    }
  };

  return (
    <View style={containerStyle.mainContainer}>
      <CustomAlertBanner
        title='The IoT device is already connected to Internet. Modify the settings to update Wifi credentials.'
        status='success'
        isVisible={bannerVisible}
      />
      <CustomAlertDialog
        title='Configure WiFi'
        description="This will update the WiFi config to the IoT device and connect to the new WiFi. Click confirm to continue with the changes."
        cancelText='Cancel'
        confirmText='Confirm'
        isOpen={visible}
        onCancelClicked={hideDialog}
        onConfirmClicked={() => {
          dispatch(BluetoothActions.configueRpiWifi.request({
            wifiSSID: formData.wifiSSID,
            wifiPassword: formData.wifiPassword
          }));
          hideDialog();
        }}
      />
      <Box>
        <VStack style={{
          marginTop: 18
        }}>
          <FormControl isRequired>
            <FormControl.Label>WiFi SSID</FormControl.Label>
            <Input
              style={inputStyle.nativeInput}
              isInvalid={'wifiSSID' in errors}
              placeholder="wifiSSID"
              onChangeText={value => setData({
                ...formData,
                wifiSSID: value
              })} />
            {'wifiSSID' in errors ? <FormControl.ErrorMessage>{errors['wifiSSID']}</FormControl.ErrorMessage> : null}

            <FormControl.Label>Wifi Password</FormControl.Label>
            <Input
              secureTextEntry={securePasswordEntry}
              style={inputStyle.nativeInput}
              isInvalid={'wifiPassword' in errors}
              InputRightElement={
                <IconButton
                  onPress={() => {
                    setSecurePasswordEntry(!securePasswordEntry)
                  }}
                  icon={<MaterialIconByName name={"eye"} />} />
              }
              placeholder="********"
              onSubmitEditing={onSubmit}
              onChangeText={value => setData({
                ...formData,
                wifiPassword: value
              })} />
            {'password' in errors ? <FormControl.ErrorMessage>{errors['wifiPassword']}</FormControl.ErrorMessage> : null}
          </FormControl>
          <Button
            leftIcon={<MaterialIconByName name={buttonIcon} />}
            onPress={onSubmit} mt="5">
            {buttonText}
          </Button>
          {'formError' in errors ? <Text color="red.500" mt="2">{errors['formError'] || ""}</Text> : null}
        </VStack>
      </Box>
    </View>
  );
};
