// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-calendar .DatePicker__calendarContainer   {
    position:relative;
}

.DatePicker__calendarContainer{
    position:absolute;
    top: 0;
}

.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 12px !important; /* default to 10px */
  }
  
  @media (max-width: 1500px) {
    .responsive-calendar {
      font-size: 11px !important;
    }
  }
  
  @media (max-width: 1200px) {
    .responsive-calendar {
      font-size: 10px !important;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-calendar {
      font-size: 9px !important;
    }
  }
  
  /* Large screens */
  @media (min-width: 2500px) {
    .responsive-calendar {
      font-size: 12px !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/DateRangePickerModal/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,MAAM;AACV;;AAEA;IACI,2DAA2D;IAC3D,0BAA0B,EAAE,oBAAoB;EAClD;;EAEA;IACE;MACE,0BAA0B;IAC5B;EACF;;EAEA;IACE;MACE,0BAA0B;IAC5B;EACF;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF;;EAEA,kBAAkB;EAClB;IACE;MACE,0BAA0B;IAC5B;EACF","sourcesContent":[".custom-calendar .DatePicker__calendarContainer   {\n    position:relative;\n}\n\n.DatePicker__calendarContainer{\n    position:absolute;\n    top: 0;\n}\n\n.responsive-calendar {\n    /* by setting font-size, all the elements will correspond */\n    font-size: 12px !important; /* default to 10px */\n  }\n  \n  @media (max-width: 1500px) {\n    .responsive-calendar {\n      font-size: 11px !important;\n    }\n  }\n  \n  @media (max-width: 1200px) {\n    .responsive-calendar {\n      font-size: 10px !important;\n    }\n  }\n  \n  @media (max-width: 768px) {\n    .responsive-calendar {\n      font-size: 9px !important;\n    }\n  }\n  \n  /* Large screens */\n  @media (min-width: 2500px) {\n    .responsive-calendar {\n      font-size: 12px !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
