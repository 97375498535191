import React, { useEffect } from 'react';
import { View } from 'react-native';
import csv from 'csvtojson';
import { Table, TableWrapper, Cell } from 'react-native-table-component';
import { Box, Link, Text } from 'native-base';
import { textStyle } from 'src/styles/textStyle';
import { tableStyle } from 'src/styles/tableStyle';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';

export default function DisplayCsvTableComponent(props) {

  const colorMode = useSelector((store: IStore) => store.app.colorMode);

  const [state, setState] = React.useState({
    title: "",
    columns: [],
    tableHead: [],
    showHead: true,
    tableData: [[], []]
  });

  useEffect(() => {
    if (props.csvFileUrl != null && props.csvFileUrl != undefined && props.csvFileUrl != "") {
      setTableData(props.title, props.columns || [], props.csvFileUrl);
    } else {
      setTableDataUsingProps()
    }
  }, []);

  useEffect(() => {
    setTableDataUsingProps()
  }, [props.tableData]);

  const setTableData = (title: string, cols: any, csvFileUrl: string) => {
    fetch(csvFileUrl)
      .then(async (response) => {
        const resp = await response.text();
        csv({
          noheader: true,
          output: "csv"
        }).fromString(resp)
          .then((csvRow) => {
            setState({
              ...state,
              title: title,
              columns: cols || Array.from(Array(csvRow[0].length).keys()),
              tableHead: csvRow[0],
              tableData: csvRow.slice(1)
            });
          })
      })
      .catch((error) => {
        console.error("some error occurred", error);
      });
  }

  const setTableDataUsingProps = () => {
    setState({
      ...state,
      title: props.title,
      columns: props.columns,
      tableHead: props.tableHead,
      tableData: props.tableData,
    });
  }

  const beautify = (data: any) => {
    if (!isNaN(parseFloat(data)) && !isNaN(data - 0)) {
      let floatData = parseFloat(data);
      if (Math.ceil(floatData) == Math.floor(floatData)) {
        return data;
      } else {
        return floatData.toFixed(2);
      }
    } else {
      return data;
    }
  }

  const textElement = (data: any) => (
    <Text style={[tableStyle.cellText, {
      color: colorMode === 'dark' ? 'white' : 'black'
    }]}>{data}</Text>
  );

  const linkElement = (data: any) => (
    <Link
      isExternal
      style={tableStyle.cellText}
      href={data}
    >Click to View</Link>
  );

  const headElement = (data: any) => (
    <Text style={tableStyle.cellText}>{data}</Text>
  );

  const dataElement = (data: any) => {
    return textElement(beautify(data));
  }

  return (
    <Box>
      <Text style={textStyle.tableTitleText}>{state.title}</Text>
      <Table borderStyle={tableStyle.border}>
        <TableWrapper style={tableStyle.head}>
          {
            state.tableHead?.filter((cellData, index) => {
              return state.columns.includes(index);
            }).map((cellData, index) => (
              <Cell key={index} data={headElement(cellData)} />
            ))
          }
        </TableWrapper>
        {
          (state.tableData || []).map((rowData, index) => {
            return (
              <TableWrapper key={index} style={tableStyle.row}>
                {
                  rowData?.filter((rowData, index) => {
                    return state.columns.includes(index);
                  }).map((cellData, cellIndex) => (
                    <Cell key={cellIndex} data={dataElement(beautify(cellData))} />
                  ))
                }
              </TableWrapper>
            )
          })
        }
      </Table>
    </Box>
  );
};